/* Position and sizing of burger button */
.bm-burger-button {

   position: fixed;
    width: 36px;
    height: 40px;
    top: 50px; 
  right: 25px; 
  z-index: 1001; 
  margin: 0;
  
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
   
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #d9d6d6;
   
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    top: 145px;
    right: 0;
    width: 100px;
    z-index: 1001;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #202020da;
    padding: 2.5em 5.5em 0;
    font-size: 1.15em;
    width:100px;
    margin-left: 100px;

  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #ffffff;
    /* padding: 0.8em; */
    /* margin-top: 1em; */
    margin-left: -3em;
    width: 150px;
   
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    text-decoration: none;
    color: #d1d1d1;
    font-family: 'Brandon Grotesque';
    font-size: 1.3em;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(194, 186, 186, 0);
    text-decoration: none;
  }


  .container1 {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: white;
    z-index: 1000;
    border-bottom: 1px solid #d9d7d7;
   
  }

  .flexywrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 7px;
    width: 100%;
    /* margin-left: 10px; */
  }
  
.headercontainer{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Brandon Grotesque';
line-height: 1.3em;
    font-size: 1.7em;
    margin: 0;
}

.name{
    font-size: 1.3em;
    text-decoration: none;
    color: black;
}

.burger-icon img {
  width: 60px; 
  height:60px;
  padding-right: 15px;
  max-height: 100%; /* Ensure it doesn't overflow the navbar */
}