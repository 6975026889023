h2 {
display: flex;
justify-content: center;
align-items: center;
font-family: 'Brandon Grotesque';
padding-top: 25px;
padding-bottom: 30px;

}


h3 {
    display: flex;
    align-items: center;
    font-family: 'Brandon Grotesque';
    padding-top:25px;
    padding-left: 55px;

    
    }

    .bio {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
        font-family: 'Brandon Grotesque';
        padding: 25px;
        margin-left: 12%;
        margin-right: 12%;
        margin-top: 20px;
        margin-bottom: 45px;
    }

    .bioText {
        display: flex;
        flex-direction: column;
        font-family: 'Brandon Grotesque';
        padding-left: 12px;
        padding-right: 12px;
    }

    /* .bioImage {
        width: 60vh;
    } */

    .bioImageContainer {
        height: 45vw;
        display: flex;
        justify-content: center;
    }

    @media (max-width: 400px) {
        .bio {
            margin-left: 2%;
            margin-right: 2%;
        }
    }

    @media (min-width: 1000px) {
        .bioImageContainer {
           
          height:450px;
           
        }
    }