/* src/ConcertCalendar.css */
.concert-calendar {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    margin-bottom: 130px;
    
  }
  
  .concert-calendar h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .concerts {
    display: flex;
    flex-direction: column;
  }
  
  .concert {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    transition: transform 0.2s ease-in-out;
    text-decoration: none; /* Remove default underline */
    color: black;
  }
  
  .concert .date {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .concert .details {
    display: flex;
    flex-direction: column;
    color: black;
  }
  
  .concert .location {
    font-style: italic;
    margin-bottom: 5px;
    color: black;
  }
  
  .concert .description {
    color: black;
  }
  
  .concert:hover {
    transform: scale(1.02); /* Enlarge slightly on hover */
  }